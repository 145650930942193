.modal__body {
  position: fixed;
  top: 5%;
  left: 50%;
  z-index: 1001;
  transform: translateX(-50%);

  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  cursor: auto;
}

.modal__background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.2);
  cursor: auto;
}
