.loading-state {
  position: relative;
  background: url(../../images/octagon.svg) no-repeat center;
  background-size: 240px 240px;
  height: 100vh !important;
}

.spinner {
  width: 30px;
  height: 30px;
  background: url(../../images/loading-spinner.svg) no-repeat center;
  background-size: 30px 30px;
}
