@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Medium';
  src: url('./static/fonts/avant_garde_pro_medium.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Light';
  src: url('./static/fonts/sequel_sans_light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Header';
  src: url('./static/fonts/avant_garde_pro_medium.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.App {
  max-width: 100vw;
  @media screen and (max-width: 540px) {
    background-color: #f2f2f2;
  }
}

// basic form styles
@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full border-gray-400 rounded-full focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400 disabled:bg-gray-200;
  }

  [type='checkbox'] {
    @apply h-5 w-5 mr-3 border border-gray-400 text-black rounded focus:ring-black;
  }

  [type='radio'] {
    @apply h-5 w-5 mr-3 border border-gray-400 text-black focus:ring-black;
  }
}

// masonry rules
@layer utilities {
  @variants responsive {
    .masonry {
      column-count: 2;
      column-gap: 1.5rem;
    }

    .masonry-single {
      column-count: 1;
    }

    .break-inside {
      break-inside: avoid;
    }
  }
}

html, body, #root {
  @apply bg-background;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
