.widget {
  width: 540px;

  @media screen and (max-width: 540px) {
    height: 100% !important;
    width: 100%;
    transform: none;
    left: 0;
    top: 0;
  }
}


